
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MMultiSelectBox from "@/components/form/MMultiSelectBox.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { Classroom } from "@/entities/classroom";
import { TutorAuthority } from "@/entities/tutor";
import { generateRandomPassword } from "@/utils/password";
import { registerTutor, TutorMeta } from "@/api/tutor";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MSelectBox,
    MTextField,
    MMultiSelectBox
  },
  emits: ["close"],
  props: {
    tutor: Object,
    classrooms: Array
  }
})
export default class MsAddModal extends Vue {
  name = "";
  enteredName = false;
  email = "";
  enteredEmail = false;
  password = "";
  authorityText = "一般";
  authorityTexts: string[] = [];
  selectedClassroomIds: string[] = [];
  tutor!: TutorMeta;
  classrooms: Classroom[] = [];

  get selectClassrooms(): Classroom[] {
    return this.classrooms.filter(item =>
      this.selectedClassroomIds.includes(item.ref.id)
    );
  }

  get authority(): TutorAuthority {
    if (this.authorityText === "管理者") {
      return "admin";
    } else if (this.authorityText === "責任者") {
      return "supervisor";
    } else if (this.authorityText === "担当者") {
      return "employee";
    } else {
      return "general";
    }
  }

  get classroomsPlaceholder(): string {
    let text = `${this.selectClassrooms.length}教室`;
    if (this.selectClassrooms.length === 0) {
      return text;
    }
    text += ": ";
    text += this.selectClassrooms.map(item => item.data.name).join(", ");
    return text;
  }

  get validName() {
    return this.name.length > 0;
  }

  get validEmail(): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }

  get validPassword(): boolean {
    return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(this.password);
  }

  get validData() {
    return this.validName && this.email && this.validPassword;
  }

  get allEntered() {
    return this.enteredName && this.enteredEmail;
  }

  changeClassroom(id: string) {
    if (this.selectedClassroomIds.includes(id)) {
      this.selectedClassroomIds = this.selectedClassroomIds.filter(
        item => item !== id
      );
    } else {
      this.selectedClassroomIds.push(id);
    }
  }

  allEnter() {
    this.enteredName = true;
    this.enteredEmail = true;
  }

  async register() {
    this.allEnter();

    if (!this.validData) {
      return;
    }

    const confirmRes = confirm(
      `${this.email}にパスワード設定の案内メールが送られますがよろしいですか？`
    );
    if (!confirmRes) {
      return;
    }

    store.commit("START_LOADING", "スタッフ登録中...");

    try {
      await registerTutor(
        this.name.trim(),
        this.email,
        this.password,
        this.authority,
        this.authority === "general" ||
          this.authority === "employee" ||
          this.authority === "supervisor"
          ? this.selectedClassroomIds
          : []
      );
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register tutor"
      );
      return;
    }

    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.password = generateRandomPassword();
    if (this.tutor.config.data.authority === "owner") {
      this.authorityTexts = ["一般", "担当者", "責任者", "管理者"];
    } else if (this.tutor.config.data.authority === "admin") {
      this.authorityTexts = ["一般", "担当者", "責任者"];
    } else if (this.tutor.config.data.authority === "supervisor") {
      this.authorityTexts = ["一般", "担当者"];
    } else {
      this.authorityTexts = ["一般"];
    }
  }
}
